$(document).ready(function () {

    if ($('.js-header').length){
        $(window).scroll(function () {
            if ($(document).scrollTop() > 50) {
                $('.js-header').addClass('active');
            }
            else {
                $('.js-header').removeClass('active');
            }
        });
    }
    
    

 /* LOCK / RELEASE SCROLL */
    function getScrollBarWidth() {
        let el = document.createElement("div");
        el.style.cssText = "overflow:scroll; visibility:hidden; position:absolute;";
        document.body.appendChild(el);
        let width = el.offsetWidth - el.clientWidth;
        el.remove();
        return width;
    }
    window.scrollBarWidth = getScrollBarWidth();
    document.documentElement.style.setProperty('--scrollbarWidth', window.scrollBarWidth + "px");

    window.lockScroll = function () {
        let scrollTop = document.querySelector('html').scrollTop ? document.querySelector('html').scrollTop : document.querySelector('body').scrollTop;
        document.querySelector('html').classList.add('noscroll');
        document.querySelector('html').style.top = -scrollTop + 'px';
    }
    window.releaseScroll = function () {
        let scrollTop = parseInt(document.querySelector('html').style.top);
        document.querySelector('html').classList.remove('noscroll');
        document.querySelector('html,body').scrollTop = -scrollTop;
    }


/* MENU */
    $('.js-open-menu').on('click', function () {
        lockScroll();
        $('.overlay').fadeIn(200);
        $(this).parent().addClass('open');
        $('.js-menu').addClass('open');
        $('.js-header').addClass('active');
    });

    $('.js-close-menu').on('click', function () {
        releaseScroll();
        $('.overlay').fadeOut(200);
        $(this).parent().removeClass('open');
        $('.js-menu').removeClass('open');
        $('.js-header').removeClass('active');

    });


    $('.js-open-sub-menu > a').on('click', function(event){
        event.preventDefault();
    });
    $('.js-open-sub-menu').on('click', function(){
        $(this).find('.sub-menu').toggleClass('active');
        
    });


/* SWITCH LANG */
    $('.js-lang-button').on('click', function(){
        if ($('.js-lang-content').hasClass('active') ){
            $('.js-lang-content').removeClass('active');
        }
        else{
            $('.js-lang-content').addClass('active');
        }
    });

/* OPEN / CLOSE ACTIONS MOBILE  */
$('.js-open-actions').on('click', function(){
    $('.js-actions-mobile').addClass('active');
});
$('.js-close-actions').on('click', function () {
    $('.js-actions-mobile').removeClass('active');
});


// Fonction pour vérifier si l'élément est visible à l'écran
function elementIsInView(elem) {
    var docViewTop = $(window).scrollTop();
    var docViewBottom = docViewTop + $(window).height();
    var elemTop = $(elem).offset().top;
    var elemBottom = elemTop + $(elem).height();
    return ((elemBottom <= docViewBottom) && (elemTop >= docViewTop));
}


/* SWIPER  */
const swiper = new Swiper('.swiper', {
    loop: true,
    slidesPerView: 1,
    spaceBetween: 40,
    autoplay: {
        delay: 4000,
        disableOnInteraction: true,  
    },

    pagination: {
        el: '.swiper-pagination',
        dynamicBullets: false,
        clickable: true,
    },

    navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
    },
    breakpoints: {
        768: {
            slidesPerView: 2,
            spaceBetween: 40,
        },
        1200: {
            slidesPerView: 3,
            spaceBetween: 40,
        },
    },
});



/* FILTERS CARDS  */

function filterResults() {
    // Récupérer les valeurs des filtres
    var ageFilter = $('#age').val();
    var sexFilter = $('#sex').val();
    var sizeFilter = $('#size').val();
    var countryFilter = $('#country').val();

    // Récupérer les valeurs des cases à cocher pour "good with"
    var goodWithFilters = [];
    $('.js-filter:checked').each(function () {
        goodWithFilters.push($(this).val());
    });

    // Itérer à travers chaque élément de la liste
    $('.js-filteredCard').each(function () {
        var $this = $(this);

        // Récupérer les attributs data-* de l'élément
        var age = $this.data('age');
        var sex = $this.data('sex');
        var size = $this.data('size');
        var country = $this.data('country');
        var goodWith = $this.data('goodwith');

        // Comparer les filtres avec les valeurs de l'élément
        var show = true;

        if (ageFilter !== 'all' && ageFilter !== age) {
            show = false;
        }
        if (sexFilter !== 'all' && sexFilter !== sex) {
            show = false;
        }
        if (sizeFilter !== 'all' && sizeFilter !== size) {
            show = false;
        }
        if (countryFilter !== 'all' && countryFilter !== country) {
            show = false;
        }

        // Comparer les filtres avec l'attribut 'Good with'
        if (goodWithFilters.length > 0 && goodWith) {
            var goodWithArray = goodWith.split(', ').map(item => item.trim());
            if (!goodWithFilters.every(filter => goodWithArray.includes(filter))) {
                show = false;
            }
        }

        // Afficher ou masquer l'élément
        if (show) {
            $this.show();
        } else {
            $this.hide();
        }
    });

    // Vérifier si tous les éléments sont masqués et ajuster la classe "active"
    if ($('.js-filteredCard:visible').length === 0) {
        $('.js-filters-no-results').addClass('active');
    } else {
        $('.js-filters-no-results').removeClass('active');
    }
}

// Appliquer le filtre chaque fois qu'un select ou une checkbox change
$('.js-filter').on('change', filterResults);

// Réinitialiser les filtres et afficher tous les résultats
$('.js-filters-reinit').on('click', function () {
    $('.js-filter').val('all');
    $('.js-filter:checkbox').prop('checked', false);
    filterResults();
});

// Exécuter le filtre initialement
filterResults();


  




});
